<template>
  <div class="relative">
    <div class="absolute inset-0">
      <NuxtImg
        src="/images/footer/mountains.png"
        sizes="100vw md:100vw"
        alt="footer bg"
        class="h-full w-full object-cover object-[85%_bottom] lg:object-cover lg:object-right"
      />

      <NuxtImg
        src="/images/footer/noise-pattern.png"
        sizes="100vw md:100vw"
        class="mask absolute inset-0 h-full w-full object-cover"
        alt="noise pattern"
      />
    </div>

    <div
      class="container relative mx-auto grid w-full grid-cols-2 gap-10 px-3 pb-[131px] pt-[39px] md:grid-cols-[2fr_1fr_1fr] md:gap-12 md:pb-[168px] lg:px-[138px] lg:pt-[120px]"
    >
      <!-- Big Footer Title -->
      <div
        class="col-span-2 row-span-2 flex items-center justify-between gap-11 md:col-span-1 md:flex-col md:items-start md:justify-start"
      >
        <NuxtImg
          src="/images/logo.png"
          sizes="40vw sm:20vw"
          alt="footer logo"
          class="min-w-[80px] sm:w-[113px]"
        />

        <span
          class="font-Kontesa text-[36px] font-bold leading-[29px] -tracking-[0.02em] text-navy lg:text-h2 lg:font-extrabold lg:-tracking-[0.02em]"
        >
          Giving together is better
        </span>
      </div>
      <!-- Fun section -->
      <div
        class="flex flex-col gap-6 sm:min-w-[140px] md:justify-self-end lg:gap-5"
      >
        <h3
          class="text-h5-mobile font-medium text-navy opacity-60 lg:text-[#8A88A7] lg:opacity-100"
        >
          Fun
        </h3>

        <div class="flex flex-col gap-4 lg:gap-1">
          <NuxtLink
            v-for="link in funLinks"
            :key="link"
            class="text-h5-mobile font-medium text-navy"
            :href="link.url"
            >{{ link.title }}</NuxtLink
          >
        </div>
      </div>
      <!-- More fun -->
      <div
        class="flex flex-col gap-6 sm:min-w-[140px] md:justify-self-end lg:gap-5"
      >
        <h3
          class="text-h5-mobile font-medium text-navy opacity-60 lg:text-[#8A88A7] lg:opacity-100"
        >
          More Fun
        </h3>

        <div class="flex flex-col gap-4 lg:gap-1">
          <NuxtLink
            v-for="link in moreFunLinks"
            :key="link"
            class="text-h5-mobile font-medium text-navy"
            :href="link.url"
            >{{ link.title }}</NuxtLink
          >
        </div>
      </div>
      <!-- Connect -->
      <div
        class="flex flex-col gap-6 sm:min-w-[140px] md:justify-self-end lg:gap-5"
      >
        <h3
          class="text-h5-mobile font-medium text-navy opacity-60 lg:text-[#8A88A7] lg:opacity-100"
        >
          Connect
        </h3>

        <div class="flex flex-col gap-4 lg:gap-1">
          <NuxtLink
            v-for="link in connectLinks"
            :key="link"
            class="text-h5-mobile font-medium text-navy"
            :href="link.url"
            >{{ link.title }}</NuxtLink
          >
        </div>
      </div>
      <!-- Boring -->
      <div
        class="hidden flex-col gap-6 sm:min-w-[140px] md:flex md:justify-self-end lg:gap-5"
      >
        <h3
          class="text-h5-mobile font-medium text-navy opacity-60 lg:text-[#8A88A7] lg:opacity-100"
        >
          Boring
        </h3>

        <div class="flex flex-col gap-4 lg:gap-1">
          <NuxtLink
            v-for="link in boringLinks"
            :key="link"
            class="text-h5-mobile font-medium text-navy"
            :href="link.url"
            >{{ link.title }}</NuxtLink
          >
        </div>
      </div>
      <!-- Lower bottom -->
      <div class="col-span-2 lg:col-span-3 lg:-mt-[61px]">
        <!-- Separator -->
        <div
          class="mb-6 mt-3 border-b border-dashed border-navy lg:mb-[80px] lg:mt-[65px]"
        />

        <!-- Copyright notice -->
        <div class="justify-between lg:flex">
          <p
            class="mb-11 max-w-[351px] text-p2 font-medium -tracking-[0.03em] text-navy md:mb-0"
          >
            © Dollar Donation Club {{ currDate.getFullYear() }} <br />
            100% of contributions go to the Dollar Donation Club Fund, a wholly
            owned subsidiary of
            <NuxtLink
              to="https://www.legacyglobal.org"
              target="_blank"
              class="underline"
              >Legacy Global</NuxtLink
            >
            Foundation Inc, a public 501(c)3 charitable organization.
          </p>
          <!-- Boring for mobile -->
          <div class="flex justify-between">
            <div class="flex flex-col gap-6 sm:min-w-[140px] md:hidden">
              <h3 class="text-h5-mobile font-medium text-navy opacity-60">
                Boring
              </h3>

              <div class="flex flex-col gap-4">
                <NuxtLink
                  v-for="link in boringLinks"
                  :key="link"
                  class="text-h5-mobile font-medium text-navy"
                  :href="link.url"
                  >{{ link.title }}</NuxtLink
                >
              </div>
            </div>

            <button
              class="group flex h-11 w-11 items-center justify-center justify-self-end rounded-[10px] bg-navy outline-none hover:bg-white md:col-span-1 lg:h-[55px] lg:w-[55px]"
              aria-label="scroll to top"
              @click="scrollTop"
            >
              <IconsArrowUp class="text-white group-hover:text-navy" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const { getFooterLinks } = useRouteUtils();
const links = getFooterLinks();
const funLinks = links.get("Fun").children;
const moreFunLinks = links.get("MoreFun").children;
const connectLinks = links.get("Connect").children;
const boringLinks = links.get("Boring").children;

const currDate = new Date();

const scrollTop = () => {
  window.scrollTo(0, 0);
};
</script>

<style scoped>
.mask {
  mask-image: linear-gradient(
    355.72deg,
    rgba(0, 0, 0, 0.2) 54.97%,
    rgba(0, 0, 0, 0) 94.67%
  );
  -webkit-mask-image: linear-gradient(
    355.72deg,
    rgba(0, 0, 0, 0.2) 54.97%,
    rgba(0, 0, 0, 0) 94.67%
  );
  filter: invert(1);
}
</style>
