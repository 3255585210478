<template>
  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 8.07843L7 2M7 2L13 8.07843M7 2L7 17"
      stroke="currentColor"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: "CustomArrowUpIcon",
};
</script>
